import React from "react";
import PropTypes from "prop-types";
import Styles from "./Styles";

const PressQuote = ({ children, attribution, link }) => {
  return (
    <Styles.PressQuote>
      {children}
      <footer>
        <p>{link ? <a href="link">{attribution}</a> : attribution}</p>
      </footer>
    </Styles.PressQuote>
  );
};

PressQuote.propTypes = {};

export default PressQuote;
