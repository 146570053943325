import React from "react"
import Styles from "./Styles"
import Section from "../../common/Section"
import PressQuote from "../../common/PressQuote"
import ButtonLink from "../../common/ButtonLink"

const PressSection = ({ id, heading, number, context }) => {
  return (
    <Styles.PressSection>
      <Section number={number} heading={heading} id={id}>
        <h3>It's Not Unusual - 04/02/2022</h3>
        <ButtonLink
          to="/press-releases/ItsNotUnusual-TheGoudies-PR-04-02-2022.pdf"
          label="Download press release"
        />
        <h3>When This Is Over - 14/07/2021</h3>
        <ButtonLink
          to="/press-releases/WhenThisIsOver-TheGoudies-PR-14-07-2021.pdf"
          label="Download press release"
        />
        <Styles.PressContent>
          <PressQuote
            attribution="Matt Spracklen - Absolute Radio Country"
            link="https://www.mattspracklen.com"
          >
            <p>"British Country Americana in it’s purest form"</p>
          </PressQuote>
          <PressQuote
            attribution="Richer Unsigned - Artist Of The Week"
            link="http://richerunsigned.com/blog/artist-of-the-week-the-goudies/"
          >
            <p>
              "What an absolute gem of a tune! Solid songwriting about what
              really matters. Their harmonies are some of the tightest and best
              suited voices we’ve heard in a long time. The fact that they can
              keep you engaged and captivated through the whole song with only
              two acoustic guitars and their voices, is a true testament to a
              well written song by talented musicians. We can’t wait to hear
              more from this duo, and will be following their journey, for
              sure."
            </p>
          </PressQuote>
          <PressQuote
            attribution="Greg Jones - Ear To The Ground Music"
            link="https://www.eartothegroundmusic.co/2021/08/13/three-americana-tracks-to-grab-your-attention-this-mid-august/"
          >
            <p>
              "If you love folk duo style music (like I do), then you won’t be
              able to click away when you try this song. The phrasing is
              incredibly patient. The word that comes to mind for me when I
              listen to this track is “endearing.” It reminds me in no small
              part of the way I felt when I first heard The Civil Wars. There’s
              an organic chemistry between the two voices and the melody is
              breathtaking. Having these vocals enter into harmony with such
              heartfelt lyrics… it just works as a balm after the past year and
              a half. This one will calm you for sure."
            </p>
          </PressQuote>
        </Styles.PressContent>
      </Section>
    </Styles.PressSection>
  )
}

export default PressSection
