import styled from "styled-components";

const PressSection = styled.div``;

const PressContent = styled.div`
  margin-top: ${({ theme }) => theme.space[4]};
  display: grid;
  grid-gap: ${({ theme }) => theme.space[4]};
`;

export default {
  PressSection,
  PressContent,
};
