import styled from "styled-components";

const PhotoSection = styled.div``;

const PhotoContent = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space[4]};

  @media ${({ theme }) => theme.device.tablet} {
    grid-template-columns: 1fr 1fr;
  }
`;

const PhotoBlock = styled.div`
  img {
    width: 100%;
    height: ${({ theme }) => theme.space[10]};
    object-fit: cover;
    object-position: top;

    @media ${({ theme }) => theme.device.mobileL} {
      height: ${({ theme }) => theme.space[11]};
    }

    @media ${({ theme }) => theme.device.laptop} {
      height: ${({ theme }) => theme.space[12]};
    }
  }
`;

const PhotoLinks = styled.div`
  margin-top: ${({ theme }) => theme.space[2]};
  display: grid;
  grid-gap: ${({ theme }) => theme.space[2]};
  grid-template-columns: 1fr 1fr;
  font-size: ${({ theme }) => theme.fontSize[1]};
`;

const PhotoCredit = styled.p`
  margin-top: ${({ theme }) => theme.space[2]};
  display: block;
  font-size: ${({ theme }) => theme.fontSize[0]};
  line-height: 1.1;
  text-transform: uppercase;
`;

export default {
  PhotoSection,
  PhotoContent,
  PhotoBlock,
  PhotoLinks,
  PhotoCredit,
};
