import styled from "styled-components";

const PressQuote = styled.blockquote`
  p {
    font-style: normal;
    font-weight: normal;
    font-family: "Courier New", Courier, monospace;
  }

  footer {
    p {
      font-style: normal;
      text-transform: uppercase;
      font-size: ${({ theme }) => theme.fontSize[2]};
      color: ${({ theme }) => theme.color.lightBrown};
      font-weight: normal;
      font-family: "ApfelGrotezk-Regular", "Helvetica", Arial, sans-serif;

      a {
        color: ${({ theme }) => theme.color.lightBrown};
      }
    }
  }
`;

export default {
  PressQuote,
};
